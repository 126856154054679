import * as React from 'react';
import {
  useLocation,
  useParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Divider from '@mui/material/Divider';
import LanguageMenu from '../components/language-menu';
import initialTheme from '../utils/theme';
import ScrollDialog from '../components/dialog';
import { AppleStoreIcon, GooglePlayIcon } from '../components/icons';
import { checkWebpSupport } from '../utils/helper';
import { SvgLogo } from '../components/logo';
import { locales } from '../constants/locales';
import { config } from '../constants/config';
import { colors } from '../constants/colors';
import CarouselMobileScreens from '../components/carousel';
import { carouselItems } from '../constants/carousel-items';

ReactGA.initialize(config.GA4Id);

export default function Home() {
  let location = useLocation();
  const { locale } = useParams();
  const { t, i18n } = useTranslation();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const themeMode = prefersDarkMode ? 'dark' : 'light';
  const language = locale;
  i18n.changeLanguage(locale);
  const isSupportWebp = checkWebpSupport();

  // Send GA4 pageview event
  const sendGA4Event = (location) => {
    const pathname = location.pathname + location.search + location.hash;
    ReactGA.send({ hitType: "pageview", page: pathname });
  }

  const theme = React.useMemo(
    () =>
      createTheme({
        ...initialTheme,
        palette: {
          ...initialTheme.palette,
          mode: themeMode,
          backgroundColor: colors[themeMode].background,
        },
      }),
    [themeMode],
  );

  React.useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      sendGA4Event(location);
    }
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="md">
        <Box sx={{ 
          width: '100vw', 
          height: '100vh', 
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          position: 'absolute',
          overflow: 'hidden',
          zIndex: -1
        }}>
          <Box sx={{ 
            width: { xs: '200vw', sm: '100vw' }, 
            height: { xs: '200vw', sm: '100vw' }, 
            backgroundColor: themeMode === 'light' ? 'rgba(254, 240, 215, 0.8)' : 'rgba(254, 240, 215, 0.1)',
            position: 'absolute',
            borderRadius: '200vw',
            right: { xs: '-105vw', sm: '-40vw' },
            top: { xs: 60, sm: '-10vw' },
          }}>
          </Box>
        </Box>
        <Box 
          sx={{ 
            display: 'flex',
            justifyContent: { xs: 'center', sm: 'left' },
          }}>
        <Box 
          sx={{ 
            position: { xs: 'relative', sm: 'absolute' },
            paddingY: { xs: 2, sm: 3 },
            maxWidth: { 
              xs: 160,
              sm: 220,
            }
          }}>
          <SvgLogo
            theme={themeMode}
            width="100%"
            height="100%"
            alt={`Dilero.app - ${t('appSlogan')}`}
          />
          </Box>
        </Box>
        <CarouselMobileScreens 
          items={carouselItems[language]} 
          isSupportWebp={isSupportWebp}
        />

        <Box sx={{ position: 'relative' }}>
          <Box 
            sx={{ 
              display: 'flex',
              zIndex: 1, 
              position: { sm: 'absolute' },
              justifyContent: { xs: 'center', sm: 'flex-start' },
              my: 1, 
              bottom: { sm: 140 }, 
              gap: 2
          }}>
            <Button
              color="secondary"
              variant="contained"
              sx={{
                padding: 1,
                minWidth: 150,
              }}
              href={config.appAppleStoreUrl[language]}
              /**
              onClick={() => {
                // Send a custom event
                ReactGA.event({
                  category: "your category",
                  action: "your action",
                  label: "Download on Apple App Store", // optional
                  //value: 99, // optional, must be a number
                  //nonInteraction: true, // optional, true/false
                  //transport: "xhr", // optional, beacon/xhr/image
                });
              }}
              */
            >
              <AppleStoreIcon sx={{ width: 32, height: 32 }} />
              <Typography component="span" variant="body1" sx={{ ml: 1, fontWeight: 600, textTransform: 'initial', lineHeight: 1, textAlign: 'center' }}>
                <Typography component="span" sx={{ fontSize: 14, fontWeight: 400 }}>
                  {t('downloadOn')}
                </Typography>
                <br />Apple Store
              </Typography>
            </Button>
            <Button
              color="secondary"
              variant="contained"
              sx={{
                padding: 1,
                minWidth: 150,
              }}
              href={config.appGooglePlayStoreUrl[language]}
              /**
              onClick={() => {
                // Send a custom event
                ReactGA.event({
                  category: "your category",
                  action: "your action",
                  label: "your label", // optional
                  value: 99, // optional, must be a number
                  nonInteraction: true, // optional, true/false
                  transport: "xhr", // optional, beacon/xhr/image
                });
              }}
              */
            >
              <GooglePlayIcon sx={{ width: 32, height: 32 }} />
              <Typography component="span" variant="body1" sx={{ ml: 1, fontWeight: 600, textTransform: 'initial', lineHeight: 1, textAlign: 'center' }}>
                <Typography component="span" sx={{ fontSize: 14, fontWeight: 400 }}>
                  {t('downloadOn')}
                </Typography>
                <br />Google Play
              </Typography>
            </Button>
          </Box>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Grid container>
          <Grid item xs={12} sm={6} sx={{
            display: 'flex',
            justifyContent: { xs: 'center', sm: 'left' },
          }}>
          <ButtonGroup variant="text" aria-label="text button group">
            <LanguageMenu
              options={locales.languages}
              language={language}
            />
            <Typography variant="body2" color="text.secondary">
              {` . `}
            </Typography>
            <ScrollDialog
              titleKey="contact"
              isOpen={location.hash === '#contact'}
              title={t('contact')}
              content={t('imprintContent')}
            />
            <Typography variant="body2" color="text.secondary">
              {` . `}
            </Typography>
            <ScrollDialog
              titleKey="imprint"
              isOpen={location.hash === '#imprint'}
              title={t('imprint')}
              content={t('imprintContent')}
            />
            <Typography variant="body2" color="text.secondary">
              {` . `}
            </Typography>
            <ScrollDialog
              titleKey="privacy"
              isOpen={location.hash === '#privacy'}
              title={t('privacy')}
              content={t('privacyContent')}
            />
          </ButtonGroup>
          </Grid>
          <Grid item xs={12} sm={6} sx={{
            textAlign: { xs: 'center', sm: 'right' },
          }}>
          <Typography variant="body2" color="text.secondary">
            {'© '}
            <Link color="inherit" href="https://dilero.app/">
              Dilero.app
            </Link>{' '}
            {new Date().getFullYear()}
          </Typography>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
