import { colors } from '../constants/colors';

export const SvgLogo = ({ theme, width, height }) => {
  const themeColors = {
    light: { 
      primary: colors.light.primary,
      secondary: colors.light.secondary,
    },
    dark: {
      primary: colors.dark.primary,
      secondary: colors.dark.secondary,
    }
  }
  return (<svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 544 160"
    width={width}
    height={height}
  >
    <g>
      <g>
        <path
          fill={themeColors[theme].primary}
          d="m144.7 31.4q4.3 0 7.9-3.3 3.4-3.2 3.4-7.9 0-4.8-3.4-7.9-3.5-3.3-7.9-3.3-4.7 0-8 3.3-3.3 3.3-3.3 7.9 0 4.5 3.3 7.9 3.4 3.3 8 3.3z" />
        <path
          fill={themeColors[theme].secondary}
          d="m144.8 44.6q-4.3 0-7.2 3-3 2.8-3 7v89.1q0 4.1 3 7 2.9 2.9 7.1 2.9 4 0 6.9-2.9 2.8-2.9 2.8-7v-89.1q0-4.1-2.8-7-2.8-3-6.8-3z" />
        <path
          fill={themeColors[theme].secondary}
          d="m226.8 147.4q0 0-2.7 2.7-2.7 2.8-6.9 2.8h-0.1-0.2q-7.8 0-14.6-2.7-6.8-2.7-11.8-7.7-5.2-5.1-8-12-2.8-6.9-2.8-15.1v-99.6q0-4.2 2.8-7.1 2.8-2.8 6.9-2.8 4.1 0 7.1 2.8 3.1 3 3.1 7.1v99.6q0 8.6 4.6 13.3 4.7 4.7 12.9 4.7 4.2 0 7 2.8 2.7 2.8 2.7 6.9 0 4.3 0 4.3z" />
        <path
          fill={themeColors[theme].secondary}
          d="m267.9 149.9q-10.1-4.3-17.6-11.8-7.6-7.5-11.8-17.5-4.3-10.1-4.3-21.2 0-11.2 4.3-21.2 4.2-10 11.8-17.5 7.5-7.5 17.6-11.8 10.1-4.3 21.2-4.3 11 0 21 4.2 10 4.3 17.7 11.8 7.5 7.5 11.8 17.4 4.3 10 4.4 21 0 4-2.7 6.7-2.7 2.7-6.9 2.7h-78.2q1.2 5.7 4.2 10.5 3 4.9 7.4 8.6 4.5 3.7 9.9 5.6 5.5 2 11.4 2 5.6 0 9.5-1 3.8-1 7.8-3.4 3.1-1.8 5.9-1.8 3.8 0 6.8 3 1.4 1.5 2.2 3.4 0.7 1.9 0.7 3.9 0 6.9-9.8 11-4.5 1.9-10.5 2.9-6 1-12.6 1-11.1 0-21.2-4.2zm55.8-58.1q-1-5.9-4-11.1-3-5.2-7.6-9-4.6-3.9-10.4-6-5.9-2.1-12.3-2.1-6.5 0-12.2 2.1-5.8 2.1-10.3 6.1-4.5 3.8-7.4 9-2.9 5.2-3.9 11z" />
        <path
          fill={themeColors[theme].secondary}
          d="m362.1 150q-3.2-2.9-3.2-7.1v-41q0-11.5 4.4-22 4.5-10.5 12.3-18.4 8-8 18.3-12.4 10.4-4.5 21.9-4.5h0.6q4.9 0 7.6 2.8 2.7 2.7 2.7 7.1 0 4.3-3 7.1-2.9 2.8-7.3 2.8h-0.6q-7.5 0-14.2 2.9-6.7 2.9-11.9 8-5.2 5.3-8.2 12.1-2.9 6.9-2.9 14.5v41q0 4.3-2.7 7.1-2.8 2.8-6.8 2.8-4.3 0-7-2.8z" />
        <path
          fill={themeColors[theme].secondary}
          d="m462.6 149.9q-9.9-4.2-17.5-11.8-7.6-7.5-11.9-17.5-4.3-10.1-4.3-21.2 0-11.1 4.3-21.1 4.3-10 11.9-17.6 7.6-7.7 17.5-11.9 9.9-4.2 21-4.2 11.2 0 21.3 4.3 10 4.3 17.5 11.8 7.5 7.6 11.7 17.6 4.2 9.9 4.2 21.1 0 11.2-4.2 21.2-4.2 10-11.7 17.5-7.5 7.5-17.5 11.8-10.1 4.2-21.3 4.2-11.1 0-21-4.2zm34.6-18.3q6.4-2.7 11.2-7.6 4.8-4.8 7.4-11.2 2.7-6.3 2.7-13.4 0-7-2.7-13.4-2.6-6.3-7.4-11.1-4.8-4.8-11.2-7.5-6.3-2.7-13.6-2.7-7.1 0-13.5 2.7-6.3 2.7-11.1 7.5-4.8 4.8-7.5 11.1-2.7 6.4-2.7 13.4 0 7.1 2.7 13.4 2.7 6.4 7.5 11.2 4.8 4.9 11.1 7.6 6.4 2.7 13.5 2.7 7.3 0 13.6-2.7z" />
        <g>
          <path
            fill={themeColors[theme].primary}
            d="m115.1 16.6v82.8q0 11.3-4.2 21.2-4.2 10-11.8 17.5-2.1 2.1-4.4 4-5.9 4.7-13.1 7.8-10 4.2-21.2 4.2-11.1 0-21-4.2-10-4.2-17.6-11.8-7.6-7.5-11.9-17.5-4.2-10.1-4.2-21.2 0-11 4.2-21.1 4.3-10 11.9-17.6 7.6-7.6 17.6-11.8 9.9-4.3 21-4.3 11.2 0 21.2 4.3 7.2 3 13.1 7.8v-40.1c0-5.6 4.6-10.2 10.2-10.2 5.6 0 10.2 4.6 10.2 10.2zm-44.9 48.6c3 3.1 5.5 7.8 7.4 13.5l6.5-6.5c-4-3.3-8.7-5.7-13.9-7zm-7.2 17.6c4.2-0.3 8.3-0.9 11.9-1.7-2.6-9.2-7-15.6-11.9-16.8zm34.3 17.2q0-0.9 0-1.8c-0.4-7.4-3-14.3-7.3-19.8q-0.4-0.6-0.8-1.1l-50.5 50.5c3.5 2.8 7.6 5.1 12 6.5q2.6 0.8 5.4 1.2 1.8 0.3 3.8 0.4 0.7 0 1.5 0 0.4 0 0.7 0l-0.2 8.1 14-11.2c12.2-5.4 20.9-17.4 21.4-31.4q0-0.7 0-1.4zm-49.7-17.7c3.3 0.5 6.7 0.7 10.3 0.7q1 0 2 0v-18.7c-5.2 1.2-9.7 8.2-12.3 18zm10.5 15.9l1.8-1.8v-10.3q-1 0-2 0-5.8 0-11-0.7c-0.9 4-1.4 8.3-1.6 12.8zm-12.7 3.2q0 4.7 0.5 9l9.1-9zm-12.6-22.9c3.4 1.4 7.4 2.6 11.7 3.3 1.9-7.1 4.8-12.9 8.3-16.6-8.1 2.1-15.1 6.9-20 13.3zm-7.1 19.7h16.6c0.1-4.7 0.7-9.2 1.5-13.3-4.8-0.8-9.1-2.1-12.8-3.7-3.1 5-5 10.8-5.3 17zm8 24.5l9.5-9.4c-0.6-3.8-0.9-7.8-0.9-11.9h-16.6c0.3 8 3.3 15.4 8 21.3zm29.4-29.4l8.3-8.2q-4 0.6-8.3 0.9z" />
          <g>
            <path
              fill={themeColors[theme].primary}
              d="m83.4 105.6c-1.3 1.3-3.4 1.3-4.7 0-1.3-1.3-1.3-3.4 0-4.7 1.3-1.3 3.4-1.3 4.7 0 1.3 1.3 1.3 3.4 0 4.7z" />
            <path
              fill={themeColors[theme].primary}
              d="m75 114c-1.3 1.3-3.4 1.3-4.7 0-1.3-1.3-1.3-3.4 0-4.7 1.3-1.3 3.4-1.3 4.7 0 1.3 1.3 1.3 3.4 0 4.7z" />
            <path
              fill={themeColors[theme].primary}
              d="m66.5 122.5c-1.3 1.3-3.4 1.3-4.7 0-1.3-1.3-1.3-3.4 0-4.7 1.3-1.3 3.4-1.3 4.7 0 1.3 1.3 1.3 3.4 0 4.7z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)}
