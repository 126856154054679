import { colors } from '../constants/colors';

// A custom theme for this app
const initalTheme = {
  palette: {
    primary: {
      main: colors.light.primary,
    },
    secondary: {
      main: colors.light.secondary,
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  }
};

export default initalTheme;
