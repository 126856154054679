export const locales = {
  defaultLanguageId: 1,
  defaultLanguageCode: "tr",
  secondLanguageId:  2,
  secondLanguageCode: "de",
  languages: [
    {
      id: 1,
      code: "tr",
      name: "Türkçe",
      emoji: "🇹🇷",
    },
    {
      id: 2,
      code: "de",
      name: "Deutsch",
      emoji: "🇩🇪",
    }
  ],
  languageCodes: {
    // languageID: "languageCode"
    1: "tr",
    2: "de",
    //3: "en",
    //4: "es",
    //5: "fr",
    //6: "it",
    //7: "ru",
    //8: "pl",
    //9: "ar",
    //10: "al",
    //11: "ku",
    //12: "nl",
  },
  languageEmojis: {
    tr: '🇹🇷',
    de: '🇩🇪',
  },
}