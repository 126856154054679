export const carouselItems = {
  tr: [
    {
      name: "Almanca Türkçe sözlük & çeviri",
      description: "Dilero ile ücretsiz ve her yerde Almanca öğren! Hızlı, kolay ve çevrimdışı",
      image: {
        jpg: "/images/screens/tr-1.jpg",
        webp: "/images/screens/tr-1.webp"
      }
    },
    {
      name: "Akıllı arama",
      description: "Uygulama Türkçe ve Almanca özel karakterleri otomatikmen tanır ve doğru önerileri sunar",
      image: {
        jpg: "/images/screens/tr-2.jpg",
        webp: "/images/screens/tr-2.webp"
      }
    },
    {
      name: "Sesli giriş",
      description: "Sesli giriş terimlerin daha hızlı çeviri yapılmasını sağlar",
      image: {
        jpg: "/images/screens/tr-3.jpg",
        webp: "/images/screens/tr-3.webp"
      }
    },
    {
      name: "Gramer bilgileri & sesli çıkış",
      description: "Sözlük terimlerin gramer bilgisine, sesli çıkışına ve her zaman güncel bir veritabanına sahiptir",
      image: {
        jpg: "/images/screens/tr-4.jpg",
        webp: "/images/screens/tr-4.webp"
      }
    },
    {
      name: "Arama geçmişi & Favoriler",
      description: "Arama geçmişinde en son yaptığın aramaları bulabilir veya favorilere beğendiğin kelimeleri ve çevirileri ekleyebilirsin",
      image: {
        jpg: "/images/screens/tr-5.jpg",
        webp: "/images/screens/tr-5.webp"
      }
    }
  ],
  de: [
    {
      name: "Türkisch-Deutsch Übersetzer",
      description: "Lerne Türkisch kostenlos und überall - mit Dilero! Schnell, einfach und offline.",
      image: {
        jpg: "/images/screens/de-1.jpg",
        webp: "/images/screens/de-1.webp"
      }
    },
    {
      name: "Intelligente Suche",
      description: "Automatische Erkennung von türkischen und deutschen Sonderzeichen",
      image: {
        jpg: "/images/screens/de-2.jpg",
        webp: "/images/screens/de-2.webp"
      }
    },
    {
      name: "Spracheingabe",
      description: "Schnellsuche per Spracheingabe",
      image: {
        jpg: "/images/screens/de-3.jpg",
        webp: "/images/screens/de-3.webp"
      }
    },
    {
      name: "Grammatikalische Infos & Sprachausgabe",
      description: "150.000 Wörter mit grammatikalischen Eigenschaften und Sprachausgabe",
      image: {
        jpg: "/images/screens/de-4.jpg",
        webp: "/images/screens/de-4.webp"
      }
    },
    {
      name: "Suchverlauf & Favoriten",
      description: "Wörter und Übersetzungen zum Suchverlauf oder zu Favoriten hinzufügen",
      image: {
        jpg: "/images/screens/de-5.jpg",
        webp: "/images/screens/de-5.webp"
      }
    }
  ]
};