export const colors = {
  light: {
    primary: '#f6901d',
    secondary: '#262626',
    background: '#f8f7f2',
    text: '#333333',
    white: '#ffffff',
    black: '#000000',
    border: '#ABABAD',
    divider: '#f8f7f2',
    disabled: '#727278',
    success: '#00ff00',
    error: '#ff0000',
    warning: '#ff0000',
  },
  dark: {
    primary: '#f6901d',
    secondary: '#ffffff',
    background: '#181818',
    text: '#ffffff',
    white: '#000000',
    black: '#ffffff',
    border: '#aaaaaa',
    divider: '#aaaaaa',
    disabled: '#aaaaaa',
    success: '#00ff00',
    error: '#ff0000',
    warning: '#ff0000',
  }
}