import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ReactMarkdown from 'react-markdown';
import Typography from '@mui/material/Typography';

export default function ScrollDialog({ isOpen = false, titleKey, title, content }) {
  const [open, setOpen] = React.useState(isOpen);
  const [scroll, setScroll] = React.useState('paper');

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
    window.history.replaceState(null, null, `#${titleKey}`);
  };

  const handleClose = () => {
    setOpen(false);
    if(window.location.hash) {
      window.history.replaceState(null, null, ' ');
    }
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Button 
        variant="text" 
        size="small"
        onClick={handleClickOpen('paper', titleKey)}
      >
        <Typography variant="body2" color="text.secondary" sx={{ textTransform: 'initial' }}>
          {title}
        </Typography>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {title}
          <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            component={"div"}
            sx={{ wordBreak: 'break-word'  }}
          >
            <ReactMarkdown>
              {content}
            </ReactMarkdown>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}