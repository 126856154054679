import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { config } from '../constants/config';
import { locales } from '../constants/locales';
import { messages } from '../constants/messages';

const i18nextOptions = {
  // order and from where user language should be detected
  order: ['path', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'subdomain'],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  lookupFromPathIndex: 1,
  lookupFromSubdomainIndex: 0,
  cleanCode: true,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: config.appDomain,

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  cookieOptions: { path: '/', sameSite: 'strict' },
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    ...i18nextOptions,
    debug: process.env.NODE_ENV === 'development' ? true : false,
    fallbackLng: locales.defaultLanguageCode,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // language resources
    resources: {
      ...messages
    },
  });

export default i18n;