import React from 'react';
import Carousel from 'react-material-ui-carousel';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function CarouselMobileScreens({ items, isSupportWebp }) {
  const imageProps = {
    width: (1284 / 5),
    height: (2778 / 5),
  }
  const carouselMinHeight = {
    xs: imageProps.height + 70 + 34, // image + left content column + indicator
    sm: imageProps.height, // image + indicator
  }

  return (
    <Carousel
      fullHeightHover={false}
      autoPlay={false}
      swipe={true}
      navButtonsAlwaysVisible={true}
      interval={7000}
      sx={{
        my: { sm: 2 },
        minHeight: { xs: carouselMinHeight.xs, sm: carouselMinHeight.sm },
        '& > div:not(.carousel-nav-wrapper, .carousel-indicator-container)': {
          minHeight: { xs: carouselMinHeight.xs, sm: carouselMinHeight.sm },
        },
        '& .carousel-nav-wrapper:nth-of-type(odd)': {
          left: { sm: '50%' }
        }
      }}
      indicatorContainerProps={{
        className: 'carousel-indicator-container',
      }}
      navButtonsProps={{
        prev: {
          style: {
            left: '50%',
          }
        }
      }}
      navButtonsWrapperProps={{
        className: 'carousel-nav-wrapper',
      }}
    >
      {items.map((item, i) => (
        <Grid
          key={i}
          container
          sx={{
            minHeight: imageProps.height
          }}
        >
          <Grid item xs={12} sm={6}
            sx={{
              alignSelf: { xs: 'center', sm: 'center' },
              textAlign: { xs: 'center', sm: 'left' },
              paddingRight: { xs: 0, sm: 2 },
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              color="text.primary"
              sx={{
                fontSize: { xs: '1.4rem', sm: '1.825rem' },
                fontWeight: '500',
                mb: { xs: 1, sm: 2 },
              }}
            >
              {item.name}
            </Typography>
            <Typography
              color="text.secondary"
              sx={{ mb: 2 }}
            >
              {item.description}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} align="center" sx={{ minHeight: imageProps.height }}>
            <Box sx={{
              position: 'relative',
              backgroundPosition: 'center',
              backgroundSize: '96%',
              backgroundRepeat: 'no-repeat',
              width: imageProps.width,
              height: imageProps.height,
              backgroundImage: `url(${isSupportWebp ? item.image.webp : item.image.jpg})`,
            }}>
              <Box sx={{
                position: 'absolute',
                left: -6,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                width: '105%',
                height: '105%',
                //filter: 'drop-shadow(rgba(20, 20, 20, 0.35) 0px 0px 6px)',
                backgroundImage: `url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxMzYyLjUgMjgwNy40IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxMzYyLjUgMjgwNy40OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPg0KCS5zdDB7ZmlsbDojRkZGRkZGO30NCjwvc3R5bGU+DQo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMTE2NC43LTFIMTk3LjlDODguMy0xLTAuNSw4Ny44LTAuNSwxOTcuNHYyNDExLjljMCwxMDkuNiw4OC44LDE5OC40LDE5OC40LDE5OC40bDAsMGg5NjYuOA0KCWMxMDkuNiwwLDE5OC40LTg4LjgsMTk4LjQtMTk4LjRsMCwwVjE5Ny40QzEzNjMuMSw4Ny44LDEyNzQuMi0xLDExNjQuNy0xeiBNMTMwMi4yLDI2MDkuM2MwLjIsNzYuMS02MS40LDEzNy43LTEzNy41LDEzNy4ySDE5Ny45DQoJYy03Ni4xLDAuNS0xMzcuNy02MS4xLTEzNy44LTEzNy4yVjE5Ny40YzAuMS03Niw2MS43LTEzNy43LDEzNy44LTEzNy45aDEzNC42YzguMSwwLjEsMTQuNyw2LjcsMTQuNywxNC45djUuMQ0KCWMwLDQxLjQsMzMuNSw3NC45LDc0LjksNzQuOWwwLDBoNTE4LjFjNDEuNCwwLDc1LTMzLjUsNzUtNzQuOXYtNS4xYzAtOC4xLDYuNi0xNC43LDE0LjctMTQuN2gxMzQuNmM3NiwwLjEsMTM3LjcsNjEuNywxMzcuNSwxMzcuNw0KCXYyNDExLjlIMTMwMi4yeiIvPg0KPC9zdmc+DQo=")`,
              }}>
              </Box>
            </Box>
          </Grid>
        </Grid>
      ))}
    </Carousel>
  )
}