import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

export default function LanguageMenu({ options, language }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(options.findIndex((x) => x.code === language));
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenLang = (index) => {
    window.location.replace(`/${options[index].code}/`);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        variant="text" 
        size="small"
        id="language-switch-button"
        aria-controls={open ? 'language-switch-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          padding: 0,
          paddingLeft: 1,
          paddingRight: 1,
          textTransform: 'none'
        }}
      >
        <Typography variant="body2" color="text.secondary" sx={{ textTransform: 'initial' }}>
          {options[selectedIndex].emoji + ' ' + options[selectedIndex].name}
        </Typography>
      </Button>
      <Menu
        id="language-switch-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'language-switch-button',
        }}
      >
        {options.map((option, index) => {
          const { code, emoji, name } = option
          return (
            <MenuItem
              key={index}
              selected={language === code}
              disabled={language === code}
              onClick={() => handleOpenLang(index)}
            >
              {emoji} {name}
            </MenuItem>
          )}
        )}
      </Menu>
    </div>
  );
}