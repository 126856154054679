export const config = {
  appGooglePackageName: 'app.dilero',
  appGooglePlayStoreUrl: {
    tr: 'https://play.google.com/store/apps/details?id=app.dilero',
    de: 'https://play.google.com/store/apps/details?id=app.dilero',
  },
  appAppleStoreId: '6447633776',
  appAppleStoreUrl: {
    tr: 'https://apps.apple.com/tr/app/dilero-almanca-t%C3%BCrk%C3%A7e-%C3%A7eviri/id6447633776',
    de: 'https://apps.apple.com/de/app/dilero-t%C3%BCrkisch-%C3%BCbersetzer/id6447633776',
  },
  appSupportEmail: 'hello@dilero.app',
  appNameShort: 'Dilero',
  appDomain: 'dilero.app',
  GA4Id: 'G-C62SXFCYW0', // Google Analytics GA4 ID
}